@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply relative block h-full text-base text-gray-800;
  }
  a {
    @apply transition-all duration-200 ease-in-out;
  }
  .tippy-box[data-theme~='gray'] {
    @apply rounded-lg bg-gray-900 px-2 py-1 text-sm font-medium text-white;
  }
  .no-arrows {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
  }
}

.tippy-box[data-theme~='gray'] {
  max-width: 18rem !important;
  @apply w-auto rounded-lg bg-gray-900 px-2
    py-1 text-sm font-medium text-white;
}

/* TODO: remove this after fix this issue on ririck-table */
._table_ tr > th:last-of-type {
  overflow: hidden !important;
}

@import '~react-phone-number-input/style.css';
@import '~react-datepicker/dist/react-datepicker.min.css';
@import '~react-placeholder/lib/reactPlaceholder.css';
@import '~tippy.js/dist/tippy.css';

@font-face {
  font-family: 'overpass';
  src: url('../fonts/overpass/overpass-thin.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'overpass';
  src: url('../fonts/overpass/overpass-thin-italic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'overpass';
  src: url('../fonts/overpass/overpass-extralight.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'overpass';
  src: url('../fonts/overpass/overpass-extralight-italic.otf')
    format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'overpass';
  src: url('../fonts/overpass/overpass-light.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'overpass';
  src: url('../fonts/overpass/overpass-light-italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'overpass';
  src: url('../fonts/overpass/overpass-regular.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'overpass';
  src: url('../fonts/overpass/overpass-italic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'overpass';
  src: url('../fonts/overpass/overpass-semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'overpass';
  src: url('../fonts/overpass/overpass-semibold-italic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'overpass';
  src: url('../fonts/overpass/overpass-bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'overpass';
  src: url('../fonts/overpass/overpass-bold-italic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'overpass';
  src: url('../fonts/overpass/overpass-extrabold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'overpass';
  src: url('../fonts/overpass/overpass-extrabold-italic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'overpass';
  src: url('../fonts/overpass/overpass-heavy.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'overpass';
  src: url('../fonts/overpass/overpass-heavy-italic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

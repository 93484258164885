@media screen and (max-width: 767px) {
  .hide-intercome #intercom-container .intercom-launcher-frame {
    display: none;
  }
}

#chat-widget-container,
#livechat-eye-catcher {
  margin-bottom: 56px;
  z-index: 5 !important;
}

@media (min-width: 1024px) {
  #chat-widget-container,
  #livechat-eye-catcher,
  #livechat-eye-catcher {
    display: none !important;
  }
}

#chat-widget-container {
  margin-bottom: 56px;
  z-index: 5 !important;
  max-height: calc(100% - 120px) !important;
}

@media (min-width: 1024px) {
  #chat-widget-container {
    margin-bottom: 0;
    max-height: 560px !important;
    max-width: 300px !important;
  }
}

/* Unexpected blue border while typing text react-select because of tailwind forms
 @see https://stackoverflow.com/a/69308190
 */
input[type='text']:focus {
  box-shadow: none;
}
